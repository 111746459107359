import PropTypes from 'prop-types';
import { Title, Button } from '../../atoms';
import './errorTemplate.scss';

/**
 * render custom error page
 * @param {Object} props
 * @param {string} props.title
 * @param {string} props.errorMessage1
 * @param {string} props.errorMessage2
 * @param {string} props.buttonLabel
 * @param {function} props.onButtonClick
 * @return {JSX.Element}
 */
export const ErrorTemplate = ({
  title,
  errorMessage1,
  errorMessage2,
  buttonLabel,
  onButtonClick,
}) => {
  return (
    <div className="errorTemplate">
      <Title size="lg" label={title} />
      <div className="errorMessage">
        <span>{errorMessage1}</span>
        <span>{errorMessage2}</span>
      </div>
      {buttonLabel && (
      <Button
        variant="navigate"
        type="button"
        size="md"
        onClick={onButtonClick}
      >
        {buttonLabel}
      </Button>
      )}
    </div>
  );
};

ErrorTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  errorMessage1: PropTypes.string.isRequired,
  errorMessage2: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  onButtonClick: PropTypes.func,
};

ErrorTemplate.defaultProps = {
  title: 'ERROR',
  errorMessage1: '알 수 없는 오류가 발생했습니다.',
  errorMessage2: '이전 화면으로 돌아가 다시 시도해주세요.',
  buttonLabel: '돌아가기',
};
