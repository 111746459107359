import { useEffect, useCallback } from 'react';
import jwtDecode from 'jwt-decode';

import { decodeToken, HotToast, Toast, validateToken } from '@utils';
import { TDecodedToken } from '../types/user';

import { useAppDispatch, useAppSelector } from '@store/hooks';
import {
  logout,
  startInitialLoading,
  assignStorageTokenToStore,
  initTimerWithExpInToken,
  selectInitialLoading,
  selectToken,
} from '@store/slices';
import { ACCESS_TOKEN, BUILD_USAGE } from '@constants';
import routes from '@routes';
import { ThunkDispatch } from 'redux-thunk';
import {
  selectDecodedToken,
  selectUser,
  TInitialState,
} from '../store/slices/auth';
import { AnyAction, Dispatch } from 'redux';
import { Sentry } from '@utils';
import { fetchAccountAndAttendance } from '@store/slices/combinedAsyncThunks';
import { ORM_MEMBER_URL, ORM_PORTAL_URL } from '@constants';


const redirectToMemberUrl = () => {
  const regex = /\/step\/[^/]+(\/readonly)?/;
  const nodeUrl = window.location.href.replace(regex, '');
  window.location.assign(`${ORM_MEMBER_URL}/?redirect_url=${encodeURIComponent(nodeUrl)}`);
};
const redirectToOrmUrl = () => {
  window.location.assign(`${ORM_PORTAL_URL}`);
}

/**
 * @author brandonwie
 * @editedAt Oct. 13, 2022
 */

/**
 * @description 'storage' handler는 React에서 execute하는 localStorage.setItem에 의해 trigger 되지 않음. 이 function은 localStorage로의 직접 접근, 다시 말해 DevTools > Application > Local Storage에서 'token' 아이템에 직접 value를 assign했을 때 trigger됨
 */
// NOTE 더 자세히 분기를 하고 싶으면 정수 여부까지도 체크를 해야 합니다.
function storageEventHandler(
  e: StorageEvent,
  dispatch: ThunkDispatch<
    {
      auth: TInitialState;
      kernel: any;
    },
    undefined,
    AnyAction
  > &
    Dispatch<AnyAction>,
) {
  if (e.key === ACCESS_TOKEN) {
    // #1. 토큰이 유효하지 않은 경우 체크
    if (!e.newValue || (e.newValue && !validateToken(e.newValue))) {
      dispatch(logout());
      redirectToMemberUrl();
      return;
    }

    // #2. term_agreement_yn 체크 (local_school_id 여부 상관없이)
    const newTokenData = decodeToken(e.newValue);
    if (newTokenData?.term_agreement_yn === false) {
      HotToast.error('앱을 이용하기 위한 추가 정보 입력이 필요합니다.');
      window.location.href = routes.online.TermsAndExtraInfoPageRoute.path;
      return;
    }
    // #3-1. 첫 입장인 경우 (newValue만 체크해주면 된다.)HotToast
    if (!e.oldValue) {
      //! campus ID를 확인한다.
      const campusId = newTokenData?.local_school_id;
      // #3-1-a. 토큰이나 캠퍼스 아이디가 없는 경우
      //TODO 함 더 보기
      if (!newTokenData || !campusId) {
        if (!newTokenData)
          HotToast.error('잘못된 인증정보로 접속을 시도하셨습니다.');
        if (!campusId)
          HotToast.error('캠퍼스 정보가 없습니다. 관리자에게 문의해주세요.');
        dispatch(logout());
        redirectToMemberUrl();
        return;
      }
      // #3-1-b. 여러 개의 캠퍼스에 속한 경우
      if (campusId === -1) {
        HotToast.error(
          '인증정보 변경이 감지되었습니다. 캠퍼스를 선택해주세요.',
        );
        // window.location.href = routes.common.SelectCampusPageRoute.path;
        redirectToMemberUrl();
        return;
      }
      // #3-1-c. 유효한 캠퍼스 ID인 경우 (참고: positive integer 양의 정수 여부는 따로 validate 하지 않지만 추후에 필요하다면 추가할 것)
      else if (campusId >= 1) {
        HotToast.error(
          '인증정보 변경이 감지되었습니다. 유저 정보를 불러옵니다.',
        );
        dispatch(startInitialLoading());
        return;
      }
      //? #3-1-d. -1을 제외한 음수인 경우 (발생 안해야 정상인 경우)
      else {
        Sentry.captureException({
          message: 'SAME_USER_MANUAL_LS_TOKEN_ASSIGN_ERROR',
          token: e.newValue,
          campusId,
        });
        HotToast.error('인증정보에 문제가 있습니다. 관리자에게 문의해주세요.');
        dispatch(logout());
        redirectToMemberUrl();
        return;
      }
    }
    // #3-2. 이미 등록된 토큰이 있던 경우
    else {
      //  관리자가 본인 LMS를 켜놓고 본인 하이잭을 하는 경우는 굉장히 드물지만 만약 발생한다면 아래 if에 걸리고 남은 시간이 받아온 새 토큰 기준으로 바뀐다.
      // NOTE null check은 할 필요가 없다.
      const prevData = jwtDecode<TDecodedToken>(e.oldValue);
      const currData = jwtDecode<TDecodedToken>(e.newValue);

      console.log('prev iat: ', prevData.iat);
      console.log('curr iat: ', currData.iat);
      if (prevData.iat < currData.iat) {
        // redirectToOrmUrl();
        redirectToMemberUrl();
        return;
      }

    // #3-2-a. 이전 사용하던 유저와 같은 유저인 경우
      if (prevData.user_id === currData.user_id) {
        // #3-2-a-a. 여러 개의 캠퍼스에 속한 경우, 이전-현재 캠퍼스 id 상관없이 캠퍼스 선택 페이지로 이동
        if (currData.user_id === -1) {
          HotToast.error(
            '인증정보 변경이 감지되었습니다. 캠퍼스 선택 화면으로 이동합니다.',
          );
          window.location.href = routes.common.SelectCampusPageRoute.path;
          return;
        }
        // #3-2-a-b. 캠퍼스 ID가 다른 경우
        if (prevData.local_school_id !== currData.local_school_id) {
          HotToast.error(
            '인증정보 변경이 감지되었습니다. 유저 정보를 다시 불러옵니다.',
          );
          dispatch(startInitialLoading());
          return;
        }
        // #3-2-a-b. 캠퍼스 ID가 같은 경우
        else {
          HotToast.error('동일한 유저로 인증정보가 갱신되었습니다.');
          // 스토어 토큰만 갈아끼운다. useInitiateTimer에서 store 토큰 변경 감지하여 새로운 타이머를 시작한다.
          assignStorageTokenToStore({ shallow: true });
          // 정보를 더 가져오거나 하진 않는다.
          return;
        }
      }
      // #3-2-b. 이전 유저와 다른 유저인 경우
      else {
        // #3-2-b-a. 캠퍼스 ID가 없는 경우
        if (currData.local_school_id === null) {
          HotToast.error('캠퍼스 정보가 없습니다. 관리자에게 문의해주세요.');
          dispatch(logout());
          return;
        }
        // #3-2-b-b. 여러 캠퍼스에 속한 경우
        else if (currData.local_school_id === -1) {
          HotToast.error(
            '인증정보 변경이 감지되었습니다. 캠퍼스 선택창으로 이동합니다.',
          );
          window.location.href = routes.common.SelectCampusPageRoute.path;
          return;
        }
        // has one campus
        else if (currData.local_school_id >= 1) {
          HotToast.error(
            '인증정보 변경이 감지되었습니다. 유저 정보를 불러옵니다.',
          );
          dispatch(startInitialLoading());
          return;
        } else {
          // -1을 제외한 음수인 경우 (발생 안해야 정상인 경우)
          Sentry.captureException({
            message: 'DIFF_USER_MANUAL_LS_TOKEN_ASSIGN_ERROR',
            prevToken: e.oldValue,
            currToken: e.newValue,
            currentCampusId: currData.local_school_id,
          });
          dispatch(logout());
          return;
        }
      }
    }
  }
}
// export const useManualStorageHandler = () => {
//   const dispatch = useAppDispatch();
//   useEffect(() => {
//     //? 정교하게 할거면 oldToken  validation 필요
//     window.addEventListener('storage', (e) => storageEventHandler(e, dispatch));
//     return () =>
//       window.removeEventListener('storage', (e) =>
//         storageEventHandler(e, dispatch),
//       );
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);
// };
export const useManualStorageHandler = () => {
  const dispatch = useAppDispatch();

  // Define the event handler using useCallback to ensure a consistent reference
  const handleStorageEvent = useCallback((e) => {
    storageEventHandler(e, dispatch);
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener('storage', handleStorageEvent);
    return () => window.removeEventListener('storage', handleStorageEvent);
    // The dependency array includes the handler to ensure it's updated if dependencies change
  }, [handleStorageEvent]);
};

/**
 * useMountUser는 initialLoading이 true (캠퍼스 id가 유효하고, 약관동의를 한 경우)에만 작동하므로 나머지 경우는 빈 값을 return 합니다.
 **/
export function useMountUser() {
  const dispatch = useAppDispatch();

  const defaultInitialLoading = useAppSelector(selectInitialLoading);

  const initialLoading = defaultInitialLoading;

  console.log('1016 useMountUser called - initialLoading', initialLoading);

  const mountUser = async () => {
    console.log(
      '%c 1016 useMountUser - Mounting User Running...',
      'color: #00ff00',
    );

    // 로그인과 같은 단계
    // try {
    //   await dispatch(fetchFingerprint()).unwrap();
    // } catch (error) {
    //   HotToast.error(String(error));
    //   return;
    // }
    dispatch(assignStorageTokenToStore()); // setup token and decodedToken
    // 사용자 정보 불러오기
    try {
      await dispatch(fetchAccountAndAttendance()).unwrap(); //setup user

      console.log('1016 useMountUser - Mounting User Success');
      return;
    } catch (error) {
      HotToast.error('유저 정보를 불러오는데 실패했습니다.', {});
      // rejectWithValue로 string으로 뱉어낸다.
      dispatch(logout());
      return;
    }
  };

  useEffect(() => {
    //* initialLoading === true 는 local_school_id > 1 인 토큰이 local storage에 저장되어 있을 때 발생한다.
    if (initialLoading === true) {
      mountUser();
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialLoading]);

  return initialLoading;
}

export const useInitiateTimer = () => {
  const token = useAppSelector(selectToken);
  useEffect(() => {
    // 실제 앱 내부 타이머는 localStorage를 사용하지만, 타이머 sync를 맞추는 것은 유저 정보를 다 들고 온 뒤에 실행한다.
    if (token) return initTimerWithExpInToken();
  }, [token]);
};
