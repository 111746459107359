import Lottie from 'react-lottie-player';
import logoAnimation from './commonLoader.json';

const CommonLoader = () => {
  return (
    <Lottie
      animationData={logoAnimation}
      loop={true}
      play={true}
      style={{ width: 350, height: 350 }}
    />
  );
};

export default CommonLoader;