import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './singleTableRow.scss';

/**
 * SingleTableRow displays a row with payment details in PaymentItemPage
 * @param {string} email
 * @param {string} cardNumber
 * @param {string} purchaseDate
 * @param {string} approvedDate //! 추후 추가 예정
 * @param {string} approvedStatus states are parsed from representative alphabets Z(pending), B(approved), D(rejected), F(cancelled)
 * @param {string} courseTitle
 * @param {number} price
 * @returns {JSX.Element} a row with purchase details
 */
export const SingleTableRow = ({
  email,
  purchaseId,
  purchaseDate,
  approvedDate,
  approvedStatus,
  courseTitle,
  roundSchedule,
  price,
}) => {
  const [approvedDateAndStatus, setApprovedDateAndStatus] = useState('');
  // parse approvedState - ! 나중에 date 추가 될 예정
  useEffect(() => {
    setApprovedDateAndStatus(parseApprovedState(approvedStatus));
  }, [approvedStatus]);

  const parseApprovedState = (approvedStatus) => {
    switch (approvedStatus) {
      case 'Z': // 결제완료 (미승인상태)
        return '승인대기';
      case 'A': // 회차 진행 전 (승인상태)
        return '승인(진행전)';
      case 'B': // 회차 진행 가능 (승인상태)
        return '승인(진행가능)';
      case 'C': // 회차 진행 중 (승인상태)
        return '진행중';
      case 'D': // 관리자 거부
        return '거부';
      case 'F': // 환불 처리
        return '취소';
      default:
        // 아무 경우에 해당하지 않을 경우
        return '오류';
    }
  };

  return (
    <tr className="sb-tableRow">
      <td>{email}</td>
      <td>{purchaseId}</td>
      <td>{purchaseDate}</td>
      <td>{approvedDateAndStatus}</td>
      <td>
        {courseTitle} - {roundSchedule}
      </td>
      <td>{new Intl.NumberFormat().format(price)}원</td>
    </tr>
  );
};

SingleTableRow.propTypes = {
  email: PropTypes.string.isRequired,
  purchaseId: PropTypes.string.isRequired,
  purchaseDate: PropTypes.string.isRequired,
  approvedDate: PropTypes.string,
  approvedStatus: PropTypes.string.isRequired,
  courseTitle: PropTypes.string.isRequired,
  roundSchedule: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
};

SingleTableRow.defaultProps = {
  email: 'brandonwie.cs@gmail.com',
  purchaseId: '111111',
  purchaseDate: '2022-01-01',
  aprrovedDate: '2022-01-01',
  approvedStatus: 'Z',
  courseTitle: '데이터 엔지니어로 가는 파이썬 첫 걸음',
  roundSchedule: 1,
  price: 150000,
};
