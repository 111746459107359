import * as PATH from './path';
import * as BUILD from './builds';
import * as PERSONA from './persona';
import * as KEYS from './keys';
import { Roles, TRoles, ROLES } from './roles';
import * as MESSAGE from './messages';
import { Http, Terms, User } from '@types';
import * as ATTENDANCE from './attendance';
import * as LOCALSCHOOLS from './localSchools';
// dev에서 작업한 Roles 살리고, next/dev에서 작업한 MESSAGE 살림
export {
  PATH,
  BUILD,
  PERSONA,
  KEYS,
  Roles,
  MESSAGE,
  type TRoles,
  ROLES,
  ATTENDANCE,
  LOCALSCHOOLS,
};

export const API_HOST = process.env.REACT_APP_API_HOST;
export const BUILD_MODE = process.env
  .REACT_APP_BUILD_MODE as typeof BUILD_ENV[keyof typeof BUILD_ENV];
export const BUILD_ENV = {
  DEVELOPMENT: 'dev',
  STAGING: 'stage',
  PRODUCTION: 'prod',
} as const;
export const BUILD_USAGE: 'legacy' | 'kdc' | 'online' = process.env
  .REACT_APP_BUILD_USAGE as User.TServices;

export const HRD_FDS_DEBUG_MODE = process.env.REACT_APP_HRD_FDS_DEBUG_MODE;

export const META_PIXEL_ID = process.env.REACT_APP_META_PIXEL_ID ?? '';

export const AUI_PREFIX = 'aui';

// SECTION Cookie
export type TCookieOptions = {
  path: string;
  maxAge: number;
  domain: string;
};
export const CookieOptions: TCookieOptions = {
  path: '/',
  maxAge: 365 * 24 * 60 * 60,
  domain: process.env.REACT_APP_PUBLIC_COOKIE_DOMAIN as string,
};

export const sessionCookieName = '_ormSessionUser';
export const sessionExist = '_ormSessionExist';
export const lastLoginCookieName = '_ormLastLogin';
export const redirectUrl = '_ormRedirectUrl';

export const sessionSnsAccessToken = '_ormSessionSnsAccessToken';
export const sessionSnsType = '_ormSessionSnsType';
export const sessionSnsCode = '_ormSessionSnsCode';

//!SECTION

// SECTION ORM-PORTAL
export const ORM_MEMBER_URL = process.env.REACT_APP_ORM_MEMBER_URL;
export const ORM_PORTAL_URL = process.env.REACT_APP_ORM_PORTAL_URL;
export const ORM_PORTAL_DASHBOARD_URL = `${ORM_PORTAL_URL}/dashboard`;
// !SECTION

//TODO: 사용안할 상수임. 제거 필요
export const ACCESS_TOKEN = 'token';
export const USER_INFO = 'user';

// #region NOTE: S3 ASSET 관련
export const S3_BUCKET_URL =
  'https://aiffel-front-prod-asset.s3.ap-northeast-2.amazonaws.com';
export const AIFFEL_LOGO_URL = `${S3_BUCKET_URL}/img/logo/aiffel_logo.png`;
export const MODU_LOGO_URL = `${S3_BUCKET_URL}/img/logo/aiffelLogo.webp`;
export const LOADING_URL = `${S3_BUCKET_URL}/img/loading`;

export const PRIVACY_POLICY_FILE_NAME = 'privacyPolicy.md';
export const TERMS_OF_USE_FILE_NAME = 'termsOfUse.md';
export const REFUND_POLICY_FILE_NAME = 'refundPolicy.md';
export const MARKETING_FILE_NAME = 'marketing.md';

export const S3_TERMS_PATH = `${S3_BUCKET_URL}/files/terms`;

export const KDC_PRIVACY_MD_URL = `${S3_TERMS_PATH}/${BUILD_USAGE}/210701/${PRIVACY_POLICY_FILE_NAME}`;
export const KDC_TERMS_USAGE_MD_URL = `${S3_TERMS_PATH}/${BUILD_USAGE}/210701/${TERMS_OF_USE_FILE_NAME}`;
export const KDC_REFUND_POLICY_MD_URL = `${S3_TERMS_PATH}/${BUILD_USAGE}/210701/${REFUND_POLICY_FILE_NAME}`;

export const ONLINE_PRIVACY_MD_URL = `${S3_TERMS_PATH}/${BUILD_USAGE}/220325/${PRIVACY_POLICY_FILE_NAME}`;
export const ONLINE_TERMS_USAGE_MD_URL = `${S3_TERMS_PATH}/${BUILD_USAGE}/220325/${TERMS_OF_USE_FILE_NAME}`;

export const COMMON_MARKETING_MD_URL = `${S3_TERMS_PATH}/common/230701/${MARKETING_FILE_NAME}`;

export const KDC_MD_URL_SET: Terms.TTermsUrlSet = {
  privacy: KDC_PRIVACY_MD_URL,
  terms: KDC_TERMS_USAGE_MD_URL,
  refund: KDC_REFUND_POLICY_MD_URL,
  marketing: COMMON_MARKETING_MD_URL,
};

export const ONLINE_MD_URL_SET: Terms.TTermsUrlSet = {
  privacy: ONLINE_PRIVACY_MD_URL,
  terms: ONLINE_TERMS_USAGE_MD_URL,
  refund: '',
  marketing: COMMON_MARKETING_MD_URL,
};
// #endregion

// #region CAMPUS ID
export const K_DIGITAL_CAMPUS_ID = '12';

// #endregion

export const getWelcomeMessage = (username: string) => {
  return username ? username + '님 환영해요 :)' : '로그인이 필요합니다 :)';
};
//! iamport.ts에 적혀있는 샘플, 모두연 계정 code와 다른데 이 부분 주석을 수정하던 여기 code가 잘못된 걸 수정하던 수정해야 함.
export const IAMPORT_MERCHANT_ID =
  BUILD_MODE === BUILD.MODE.PROD ? 'imp60766161' : 'imp18504750';

export const IMP_ID_PHONE_VERIFICATION = 'imp60766161';

export const GA_KEY =
  BUILD_USAGE === 'kdc' ? 'UA-177079556-3' : 'UA-1770795560-2';

/**
 * ERROR MESSAGE
 * */
export const LOGIN_ERROR_MSG_LIST = [
  '아이디 혹은 패스워드가 올바르지 않습니다. 확인 후 다시 로그인해주세요.',
  'Unable to log in with provided credentials.',
];

export const DIFFRENT_BROWSER_ERROR = `Request browser is not equal Login browser`;
export const TOKEN_EXPIRED = `Token has expired.`;

export const ITEM_PER_PAGE = 4;

export const USER_TYPE_STUDENT = 'student';
export const USER_TYPE_COACH = 'coach';
export const USER_TYPE_ETC = 'etc';

export const NODE_TYPE_PROJECT = 'project';

export const NORMAL = 'normal';
export const K_DIGITAL = 'kdc';

export const RECENT_NODE = 'recent';
export const NORMAL_NODE = 'normal';

export const PASSWORD_MIN_LENGTH = 10;

export const DEFAULT_KERNEL_ENV = 'local';
export const KERNEL_USAGE_DEFAULT = 'local';
export const KERNEL_USAGE_LOCAL = 'local';
export const KERNEL_USAGE_CLOUD = 'cloud';

export const KERNEL_IDLE = 'idle';
export const KERNEL_BUSY = 'busy';

export const LECTURE_NODE_FINISH = 'D';
export const PROJECT_NODE_FINISH = 'E';

export const LOCAL_KERNEL_SETTING = {
  kernelName: 'aiffel',
  ipAddr: 'http://127.0.0.1:8888',
  secretToken: 'test-secret',
};

export const COMCOM_CLOUD_JUPYTER_SECRET_TOKEN = 'aiffel';

// 해당 커널에 대한 정보 (노트북 실행 기준)
export let DEFAULT_KERNEL_CONFIG = {
  kernelOptions: {
    name: 'aiffel',
    serverSettings: {
      baseUrl: 'http://127.0.0.1:8888',
      token: 'test-secret',
      selector: '[dataExecutable]',
    },
    requestKernel: true,
    predefinedOutput: true,
    codeMirrorconfig: {
      theme: 'abcdef',
      indentUnit: 4,
      lineNumbers: true,
    },
  },
};

export const LOCAL_KERNEL_ENDPOINT = 'http://127.0.0.1:8888';
export const LOCAL_KERNEL_COMMAND = `jupyter notebook --NotebookApp.token=test-secret --NotebookApp.allow_origin='*' --NotebookApp.allow_remote_access='True' --no-browser`;

export const CLOUD_KERNEL_NAME = 'python3';

export const HEADER_USAGE_STEP = 'step';
export const HEADER_USAGE_NODE = 'node';

export const EMBEDDED_JUPYTER = 'jupyter';
export const EMBEDDED_SHELL = 'shell';

export const SUCCESS_VAL = 'success';
export const PENDING_VAL = 'pending';
export const FAIL_VAL = 'failed';

export const TOPIC_CATEGORY: Http.Response.TForumCategory[] = [
  {
    id: 1,
    name: 'general',
    color: '#FFD747',
  },
  {
    id: 2,
    name: 'bug',
    color: '#E46448',
  },
  {
    id: 3,
    name: 'suggestion',
    color: '#5A7FFF',
  },
  {
    id: 4,
    name: 'tips&knowhow',
    color: '#48E0A9',
  },
];
export const FILTER_ORDER_LIKE = '-like_count';
export const FILTER_ORDER_UPDATE = '-updated_at';

export const EDITOR_USAGE_EDIT = 'EDIT';
export const EDITOR_USAGE_CREATE = 'CREATE';

export const EDITOR_USAGE_POST_EDIT = 'POST EDIT';
export const EDITOR_USAGE_POST_CREATE = 'POST CREATE';

export const EDITOR_USAGE_REPLY_EDIT = 'REPLY EDIT';
export const EDITOR_USAGE_REPLY_CREATE = 'REPLY CREATE';

export const LIKE_USAGE_TOPIC = 'LIKE TOPIC';
export const LIKE_USAGE_POST = 'LIKE POST';

export const CONTAINER_ALREADY_EXIST = 'Container Already Exist.';
export const CONTAINER_QUOTA_EXCEED = 'Container quota exceeded';

export const PERSONA_SURVEY = '/persona/survey';
export const PERSONA_RESULTS = '/persona/results';

//*임시: online 페이지 한 번에 변경 위해 미리 제작
export const ONLINE_HOME = '/online';
export const ONLINE_TERMS_AND_CONDITIONS = '/online/terms-and-conditions';

export const PROJECT_NODE_POPUP_ANIMATIONS = {
  GOT_CREDIT_JSON:
    'https://aiffel-front-prod-asset.s3.ap-northeast-2.amazonaws.com/animations/project_node_popup/credit_O.json',
  TIMEOUT_JSON:
    'https://aiffel-front-prod-asset.s3.ap-northeast-2.amazonaws.com/animations/project_node_popup/timeout_y.json',
  PROJECT_JSON:
    'https://aiffel-front-prod-asset.s3.ap-northeast-2.amazonaws.com/animations/project_node_popup/project_y.json',
};

export const commonButtonStyle = `mt-3 inline-flex w-full justify-center rounded-md border bg-white px-4 py-2 text-base font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm`;
export const basicButtonStyle = `border-gray-300 text-gray-700 hover:bg-gray-50 ${commonButtonStyle}`;
export const primaryButtonStyle = `bg-y-500 border-y-500 text-k-900 hover:bg-y-600 hover:border-y-600 ${commonButtonStyle}`;
export const errorButtonStyle = `bg-red-600 hover:bg-red-700 border-red-700 hover:border-red-700  ${commonButtonStyle}`;

export const KDT_MARKETING_URL =
  'https://modulabs.notion.site/AIFFEL-8a6d857ca37940dc8999ff11a405738e';
export const SOCAR_MARKETING_URL = `https://aiffel.io`;

export const ONLINE_MARKETING_URL =
  'https://drive.google.com/file/d/1Jk59-BEhBd9ImRPcHhp-lZ_o5HguTOmi/view?usp=sharing';

export const STORAGE_NAME_BANNER = 'isBannerHidden';

export const MODULABS_MEMBER_URL = `${process.env.REACT_APP_MODULABS_MEMBER_URL}`;