import { instance } from '../provider';

/* 계정정보 */
export const getAccount = (userId) => {
  return instance.get(`/api-users/users/${userId}/`);
};

export const getProfile = () => {
  return instance.get(`/api-users/profile/`);
};
