import { Route, Routes } from 'react-router-dom';
import routes from '../../routes';
import PublicOnly from './PublicOnly';
import RequiredAuth from './RequireAuth';

const renderingRoutes = [
  //TODO: 추후에 해당 내용 주석된 부분 portal 로 옮긴 후 삭제처리 필요. - 2023.08.16 (Tax)
  // routes.kdc.LandingPageRoute, // KDC 랜딩 페이지
  // routes.kdc.TermsPageRoute, // 약관 페이지?.. 필요한가?
  // routes.kdc.FindClassPageRoute, // 강의 찾기 페이지
  // routes.kdc.CheckNBCPageRoute,// NBC 카드 체크 페이지
  // routes.kdc.CourseApplyPageRoute, // 강의 신청 페이지
  // routes.kdc.CourseAPplyConfirmPageRoute, // 강의 신청 완료 페이지

  // routes.kdc.PurchasePageRoute, // 결제 페이지
  // routes.kdc.PurchaseConfirmPageRoute, // 결제 완료 페이지
  // routes.kdc.AssessmentPageRoute,

  // routes.common.FindPasswordPageRoute, // 비밀번호 재설정 페이지 스텝1
  // routes.common.ResetPasswordPageRoute, // 비밀번호 재설정 페이지 스텝2
  // routes.common.AttendancePageRoute, //출결 페이지
  routes.common.NodesPageRoute,
  routes.common.StepsPageRoute,
  routes.common.ReadonlyStepsPageRoute,
  // routes.common.ReleaseNotePageRoute, // 릴리즈노트 페이지
  // routes.common.PrintPageRoute, // 수료증 출력 페이지
  // routes.common.ClassroomPageRoute, // 개발할 땐 임시로 필요함. 추후 삭제 필요

  routes.common.HijackPageRoute,
  routes.common.RedirectPageRoute,

  /** 내일배움클래스 상세페이지 */ //TODO 전체 삭제 필요 - 2023.08.16 (Tax)
  // routes.kdc.PythonBascisRoute,
  // routes.kdc.MetaverseAiBasicRoute,
  // routes.kdc.MlBasicRoute,
  // routes.kdc.DataAbcRoute,
  // routes.kdc.DlTrainingRoute,
  // routes.kdc.DlTheoryRoute,
  // routes.kdc.KagglerDaRoute,

  /** 공지사항 */ //TODO 공지사항이 들어가야하나? - 2023.08.16 (Tax)
  // routes.common.NoticeBoardPageRoute,
  // routes.common.NoticePostPageRoute,

  /** 통합 로그인 페이지 */ // Signin/Signup 페이지는 통합 로그인 사이트로 이동 필요
  // routes.common.IntegratedSignInPageRoute,
  // routes.common.IntegratedSignUpPageRoute,
  // routes.common.SelectCampusPageRoute,
  // routes.common.RegisterInvitedUserPageRoute, // 초대받은 사용자 회원가입 페이지

  /** Terms And Conditions */ //TODO: 해당 페이지들 멤버(통합 로그인)페이지로 이동 필요
  // routes.common.IntergratedPrivacyPolicyPageRoute,
  // routes.common.IntergratedTermsPageRoute,
  // routes.common.IntergratedRefundPolicyPageRoute,
  // routes.common.IntergratedMarketingPageRoute,

  /** Not Found */
  routes.kdc.NotFoundPageRoute,
];

export default function KDCRoutes() {
  return (
    <Routes>
      {renderingRoutes.map((route, key) => {
        const Component: React.FC = () => {
          // private 부분은 추후에 그 이름이 type B로 변경되거나, A,B가 모두 고려된 적절한 segregation 기준명을 정하여 보강해야 함
          if (route.private || route.allowedPermissions) {
            return (
              <RequiredAuth allowedRoles={route.allowedPermissions}>
                <route.component />
              </RequiredAuth>
            );
          }

          if (route.publicOnly) {
            return (
              <PublicOnly>
                <route.component />
              </PublicOnly>
            );
          }
          return <route.component />;
        };

        return <Route key={key} path={route.path} element={<Component />} />;
      })}
    </Routes>
  );
}
