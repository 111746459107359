import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorTemplate } from '../../stories/templates';
import styles from './index.module.scss';

export default function NotFoundPage() {
  const navigate = useNavigate();
  const handleHomeButtonClick = useCallback(() => {
    // TODO 후에 route object 이용한 constant로 변경
    navigate('/');
  }, [navigate]);

  return (
    <div className={styles.notFoundPage}>
      <ErrorTemplate
        title="404"
        errorMessage1="요청하신 페이지를 찾을 수가 없습니다."
        errorMessage2="주소를 확인해주세요."
        buttonLabel=""
        onButtonClick={handleHomeButtonClick}
      />
    </div>
  );
}
