import { useAppSelector } from '@store/hooks';
import { selectDecodedToken } from '@store/slices';
import { BUILD_ENV, BUILD_MODE } from '@constants';

const useOrmBizUrls = () => {
  const USG_LOCAL_SCHOOL_ID = 26;
  const decodedToken = useAppSelector(selectDecodedToken);
  let ORMBIZ_PORTAL_URL = '';
  switch (decodedToken?.local_school_id) {
    case USG_LOCAL_SCHOOL_ID:
      ORMBIZ_PORTAL_URL =
        BUILD_MODE === BUILD_ENV.DEVELOPMENT
          ? 'https://dev-usg.ormbiz.co.kr'
          : 'https://usg.ormbiz.co.kr';
      break;
  }
  if (
    window.location.hostname === 'localhost' ||
    window.location.hostname === '127.0.0.1'
  ) {
    ORMBIZ_PORTAL_URL = 'http://localhost:3001';
  }
  const ORMBIZ_PORTAL_DASHBOARD_URL = `${ORMBIZ_PORTAL_URL}/dashboard`;

  return {
    ORMBIZ_PORTAL_URL,
    ORMBIZ_PORTAL_DASHBOARD_URL,
  };
};

export default useOrmBizUrls;
