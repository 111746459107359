import { useEffect } from 'react';
import { useCookies } from 'react-cookie';

import {
  CookieOptions,
  lastLoginCookieName,
  sessionCookieName,
  sessionSnsAccessToken,
  sessionSnsCode,
  sessionSnsType,
} from '@constants';

const useCookieManager = () => {
  const [cookies, setCookie, removeCookie] = useCookies([
    sessionCookieName,
    lastLoginCookieName,
    sessionSnsAccessToken,
    sessionSnsCode,
    sessionSnsType,
  ]);

  const setSessionCookie = (value: string) => {
    setCookie(sessionCookieName, value, { ...CookieOptions });
  };

  const removeSessionCookie = () => {
    removeCookie(sessionCookieName, { ...CookieOptions, maxAge: 0 });

    //NOTE: SNS 경우 로그아웃 시, SNS 관련 쿠키도 삭제
    removeCookie(sessionSnsAccessToken, { ...CookieOptions });
    removeCookie(sessionSnsCode, { ...CookieOptions });
    removeCookie(sessionSnsType, { ...CookieOptions });
  };

  useEffect(() => {}, [
    cookies[sessionCookieName],
    cookies[lastLoginCookieName],
  ]);

  const lastLoginMethod = cookies[lastLoginCookieName] ?? null;
  const userToken = cookies[sessionCookieName] ?? null;
  const snsAccessToken = cookies[sessionSnsAccessToken] ?? null;
  const snsCode = cookies[sessionSnsCode] ?? null;
  const snsType = cookies[sessionSnsType] ?? null;

  return {
    lastLoginMethod,
    userToken,
    setSessionCookie,
    removeSessionCookie,
    snsAccessToken,
    snsCode,
    snsType,
  };
};

export default useCookieManager;
