import { BUILD_USAGE, sessionCookieName } from '@constants';
import { CookieOptions } from '@utils/Cookie';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

export const clearStorageAndTimer = (
  timer: ReturnType<typeof setTimeout>,
): void => {
  window.localStorage.clear();

  if (BUILD_USAGE === 'kdc') {
    cookies.remove(sessionCookieName, CookieOptions);
  }
  window.clearTimeout(timer);
};
