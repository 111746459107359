import { useEffect } from 'react';

import { useAppSelector } from '@store/hooks';
import { selectUser } from '@store/slices';
import { BUILD, BUILD_ENV, BUILD_MODE, BUILD_USAGE } from '@constants';
import { ChannelService } from '@utils';

const getPluginKey = () => {
  // if (BUILD_USAGE === BUILD.USAGE.K_DIGITAL)
  //   return 'e0369484-df74-490f-9397-114bbeeac8e0';  // KDC, B2C
  // else if (BUILD_USAGE === BUILD.USAGE.ONLINE)
    return '26d66946-7d37-4937-99a3-2f38e4576d34';  // B2B
};

export const useChannelTalk = () => {
  const PLUGIN_KEY = getPluginKey();
  const user = useAppSelector(selectUser);

  useEffect(() => {
    let settings = {
      pluginKey: PLUGIN_KEY,
      language: 'ko',
    };
    if (
      BUILD_USAGE === BUILD.USAGE.K_DIGITAL ||
      BUILD_USAGE === BUILD.USAGE.ONLINE
    ) {
      if (user) {
        const memberId =
          BUILD_MODE !== BUILD_ENV.PRODUCTION
            ? `${BUILD_MODE}_aiffel_member_`.concat(user.userId.toString())
            : 'aiffel_member_'.concat(user.userId.toString());
        ChannelService.boot({
          ...settings,
          memberId,
          profile: {
            name: user.name,
            mobileNumber: user.phoneNumber,
            email: user.email,
          },
        });
      } else {
        ChannelService.boot(settings);
      }
    }
    return () => ChannelService.shutdown();
  }, [user]);
};
