export const ForumIcon = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="inherit"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.38322 13.622V8.06641H4.29989C3.74735 8.06641 3.21745 8.2859 2.82675 8.6766C2.43605 9.0673 2.21655 9.59721 2.21655 10.1497V22.6497C2.21759 22.7787 2.25452 22.9048 2.3232 23.014C2.39189 23.1232 2.48962 23.211 2.60544 23.2678C2.71702 23.3185 2.84005 23.3388 2.96202 23.3266C3.08399 23.3144 3.20056 23.2702 3.29989 23.1984L6.87627 20.5664H16.2096C16.4728 20.5742 16.7348 20.5278 16.9794 20.4303C17.2239 20.3327 17.4459 20.1859 17.6314 19.9991C17.8169 19.8122 17.9621 19.5893 18.058 19.344C18.1539 19.0988 18.1984 18.8365 18.1888 18.5733V17.7886H10.5499C9.44482 17.7886 8.38501 17.3496 7.60361 16.5682C6.82221 15.7868 6.38322 14.727 6.38322 13.622Z"
      fill="inherit"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5499 3.20508H22.3554C22.8959 3.2231 23.4081 3.45053 23.7839 3.83929C24.1597 4.22806 24.3696 4.7477 24.3693 5.28841V17.7329C24.369 17.8637 24.3316 17.9919 24.2616 18.1025C24.1915 18.213 24.0917 18.3016 23.9735 18.3579C23.8613 18.4076 23.738 18.4268 23.616 18.4134C23.494 18.3999 23.3777 18.3544 23.2791 18.2815L19.9596 15.7051H10.5499C9.99735 15.7051 9.46745 15.4856 9.07675 15.0949C8.68605 14.7042 8.46655 14.1743 8.46655 13.6217V5.28841C8.46655 4.73588 8.68605 4.20597 9.07675 3.81527C9.46745 3.42457 9.99735 3.20508 10.5499 3.20508ZM11.5915 10.1497C12.1668 10.1497 12.6331 9.68337 12.6331 9.10807C12.6331 8.53278 12.1668 8.06641 11.5915 8.06641C11.0162 8.06641 10.5498 8.53278 10.5498 9.10807C10.5498 9.68337 11.0162 10.1497 11.5915 10.1497ZM16.7999 9.10807C16.7999 9.68337 16.3335 10.1497 15.7582 10.1497C15.1829 10.1497 14.7166 9.68337 14.7166 9.10807C14.7166 8.53278 15.1829 8.06641 15.7582 8.06641C16.3335 8.06641 16.7999 8.53278 16.7999 9.10807ZM19.925 10.1497C20.5003 10.1497 20.9666 9.68337 20.9666 9.10807C20.9666 8.53278 20.5003 8.06641 19.925 8.06641C19.3497 8.06641 18.8833 8.53278 18.8833 9.10807C18.8833 9.68337 19.3497 10.1497 19.925 10.1497Z"
      fill="inherit"
    />
  </svg>
);

export const HomeIcon = () => (
  <svg
    width="25"
    height="26"
    viewBox="0 0 25 26"
    fill="inherit"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.203 12.9749L13.2985 0.953474C13.22 0.866685 13.1267 0.797829 13.0241 0.750849C12.9214 0.703869 12.8113 0.679688 12.7002 0.679688C12.5891 0.679688 12.479 0.703869 12.3763 0.750849C12.2737 0.797829 12.1804 0.866685 12.1019 0.953474L1.19741 12.9749C0.879728 13.3253 0.699707 13.8014 0.699707 14.2979C0.699707 15.3289 1.4595 16.1671 2.39402 16.1671H3.54298V24.7451C3.54298 25.262 3.92155 25.6797 4.39013 25.6797H11.0059V19.1374H13.9709V25.6797H21.0103C21.4789 25.6797 21.8574 25.262 21.8574 24.7451V16.1671H23.0064C23.4564 16.1671 23.888 15.9715 24.2056 15.6181C24.8648 14.8879 24.8648 13.705 24.203 12.9749Z"
      fill="inherit"
    />
  </svg>
);

export const SpeakerIcon = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="inherit"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.8649 0.427368C23.7509 0.427368 23.6339 0.448366 23.5169 0.49636L6.22708 7.44349H1.3077C1.04373 7.44349 0.827759 7.66546 0.827759 7.94143V16.9103C0.827759 17.1863 1.04373 17.4082 1.3077 17.4082H4.35532C4.24433 17.7562 4.18734 18.1252 4.18734 18.5001C4.18734 20.4769 5.80114 22.0847 7.78689 22.0847C9.44868 22.0847 10.8495 20.9568 11.2635 19.433L23.5199 24.3584C23.6369 24.4034 23.7539 24.4274 23.8679 24.4274C24.3748 24.4274 24.8278 24.0014 24.8278 23.4315V1.42324C24.8248 0.853315 24.3748 0.427368 23.8649 0.427368ZM7.78689 19.9339C6.99199 19.9339 6.34707 19.292 6.34707 18.5001C6.34707 18.1642 6.46405 17.8432 6.67703 17.5882L9.22371 18.6111C9.16372 19.349 8.54279 19.9339 7.78689 19.9339Z"
      fill="inherit"
    />
  </svg>
);
