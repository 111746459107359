// ANCHOR [Forum][Notification]
import { instance } from '../provider';
/**
 *
 * @param { number } postsPerPage
 * @returns { Object } all_count, all_list
 */

export const getAllNotification = (postsPerPage) => {
  return instance.get(
    `/notification/api/all_list?max=${postsPerPage.toString()}`,
  );
};

/**
 * @returns {array} an array of the user's notifications
 * TODO make requests contain a right header not to pass it all around
 */
export const getAllNotificationCount = () => {
  return instance.get('/notification/api/all_count/');
};

export const getUnreadNotification = () => {
  return instance.get('/notification/api/unread_list/');
};

/**
 * Returns the number of user's unread notification
 * @returns {object} unread_count
 */
export const getUnreadNotificationCount = () => {
  return instance.get('/notification/api/unread_count/');
};

/**
 * mark the post as read to the user
 * @param { number } slug post identifier
 * @returns
 */
export const postMarkAsRead = (slug) => {
  const url = `/notification/mark-as-read/${JSON.stringify(slug)}/`;
  return instance.post(url);
};

/**
 * mark all posts as read
 */
export const postMarkAllAsRead = () => {
  return instance.post('/notification/mark-all-as-read/');
};
