import LMSRoutes from '@components/AppRouter/LMSRoutes';
import routes from '@routes';
import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import styles from './index.module.scss';
import { BUILD_USAGE } from '@constants';

export type SubHeaderProps = {
  breadcrumbs?: {
    title: string;
    target?: {
      pathname: string;
      state?: any;
      replace?: boolean;
    };
  }[];
  title?: string | React.ReactNode;
  chips?: string[];
  children?: React.ReactNode;
};

export default function SubHeader({
  title,
  chips,
  breadcrumbs,
  children,
}: SubHeaderProps) {
  const location = useLocation();

  // console.log('1129 breadcrumbs', { breadcrumbs, location });
  const pathName = location?.pathname;

  const profilePath = routes.lms.ProfilePageRoute.path;

  return (
    <div className={styles.subHeader}>
      <div className={styles.container}>
        {pathName !== profilePath && (
          <div className={styles.navigationAndTitle}>
            {breadcrumbs && (
              <nav>
                {breadcrumbs.map((item, index) => (
                  <React.Fragment key={index}>
                    {BUILD_USAGE !== 'kdc' && item.target ? (
                      <Link
                        to={{ pathname: item.target.pathname }}
                        state={item.target.state}
                        replace={item.target.replace ?? false}
                      >
                        {item.title}
                      </Link>
                    ) : (
                      <span>{item.title}</span>
                    )}
                    {index < breadcrumbs.length - 1 && <span> / </span>}
                  </React.Fragment>
                ))}
              </nav>
            )}
            {title && (
              <h2>
                {title}{' '}
                {chips &&
                  chips.map((text, index) => (
                    <span className={styles.chip} key={index}>
                      {text}
                    </span>
                  ))}
              </h2>
            )}
          </div>
        )}

        {children}
      </div>
    </div>
  );
}
