import classNames from 'classnames';
import styles from './index.module.scss';
import React from 'react';
import CommonLoader from '@components/CommonLoader';

export interface LoadingPageProps {
  className?: string;
  message?: string;
}

const LoadingPage: React.FC<LoadingPageProps> = ({ className, message }) => {
  return (
    <div className={classNames(styles.loadingPage, className)}>
      <CommonLoader />
      {message && <p className={classNames(styles.message)}>{message}</p>}
    </div>
  );
};

export default LoadingPage;
