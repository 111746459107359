import { Cookies } from 'react-cookie';

type TCookieOptions = {
  path: string;
  maxAge: number;
  domain: string;
};
export const CookieOptions: TCookieOptions = {
  path: '/',
  maxAge: 365 * 24 * 60 * 60,
  domain: process.env.REACT_APP_PUBLIC_COOKIE_DOMAIN as string,
};
