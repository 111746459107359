import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';

export const KDCMetaTagWrapper = ({
  children,
}: {
  children: React.ReactChild;
}) => {
  const origin = useMemo(() => {
    return window.location.origin;
  }, [window.location]);

  return (
    <>
      <Helmet>
        <title>오름클래스</title>
        <meta
          name="description"
          content={
            '파이썬, 딥러닝, 데이터분석 등 AI 실무중심 실습을 가볍게 시작하고, 수료 후 교육비 전액 환급 받아가세요.'
          }
        />
        <meta
          name="keywords"
          content={
            '국비교육, 내일배움카드, 고용노동부, 코딩, 코딩공부, 코딩교육, 코딩테스트, 컴퓨터, 데이터분석, 부트캠프, 코딩부트캠프, 개발자, 취업, ai그림, 자율주행, 스터디, 해커톤, 사이드 프로젝트, 온라인코딩, AI교육, 머신러닝, 딥러닝, 파이토치, 싸이킷런, 케라스, 판다스, 넘파이, NLP, YOLO, 파이썬독학, 프로그래밍, 온라인 교육, 온라인강의, 기업교육, HRD, HRD교육, 온라인교육플랫폼, 온라인학습, 직장인온라인교육, 온라인직무교육, 교육플랫폼, 교육콘텐츠, 스마트학습, 기업온라인교육, 임직원교육, 디지털교육, 직무교육, HR교육, 직장인교육, 회사교육, 회사복지, 직원교육프로그램, 사내교육, 외부교육, 온라인강의플랫폼, 신입직원교육, 직무역량교육, 교육프로그램, 데이터사이언스, 코딩이란, AI'
          }
        />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="오름클래스" />
        <meta
          property="og:title"
          content="오름클래스 - 모두의연구소가 만든 온라인클래스"
        />
        <meta
          property="og:description"
          content="파이썬, 딥러닝, 데이터분석 등 AI 실무중심 실습을 가볍게 시작하고, 수료 후 교육비 전액 환급 받아가세요."
        />
        <meta
          property="og:image"
          content={`${origin}/images/og-image-orm-class.jpg`}
        />
        <meta property="og:url" content={origin} />
        <link rel="canonical" href={origin} />
      </Helmet>
      {children}
    </>
  );
};
