/**
 * @author brandonwie
 * @created Sep 20, 2022
 */
import { AxiosRequestConfig } from 'axios';

import { _http as request, genFormData } from './helpers';
import { Http } from '@types';
import { KEYS } from '@constants';

export const http = {
  /**************************************************************
   *! AUTHENTICATION 인증 관련
   *************************************************************/
  auth: {
    postLogin: (data: Http.Request.ILogin, config?: AxiosRequestConfig) => {
      return request.post<Http.Response.TLogin, Http.Request.ILogin>(
        '/login/',
        genFormData({ ...data }), // workaround but not equivalent since the instance is not the same; you can use type alias not to use spread operator
        config,
      );
    },
    postRefreshToken: (
      data: Http.Request.TRefreshToken,
      config?: AxiosRequestConfig,
    ) => {
      return request.post<
        Http.Response.TRefreshToken,
        Http.Request.TRefreshToken
      >('/refresh-token/', genFormData(data), config);
    },
  },

  /**SECTION : Stage 관련 */
  stage: {
    fetchLearningInfo: () => {
      return request.get<Http.Response.TUserLearningInfo>(
        `/api-users/learning-info`,
      );
    },
  },

  //!SECTION

  /**************************************************************
   *! User 사용자 정보 관련
   *************************************************************/
  user: {
    getAccountInfo: (_: void, config?: AxiosRequestConfig) => {
      return request.get<Http.Response.TGetAccount>('/account', config);
    },

    // TODO: KDC 출석체크 API -> 헷갈릴수 있으니 추후 rename 필요함.
    getAttendance: (_: void, config?: AxiosRequestConfig) => {
      return request.get<Http.Response.TGetAttendance>(
        '/auth/attendance/',
        config,
      );
    },
    getCampusList: (_: void, config?: AxiosRequestConfig) => {
      return request.get<Http.Response.TLocalSchool[]>('/localschool/', config);
    },
    getClassroomInfo: (_: void, config?: AxiosRequestConfig) => {
      return request.get<Http.Response.TResponseClassroom>(
        '/api-stages/classroom/',
      );
    },

    postAttendanceUpdateStatus: (
      data?: Http.Request.TUpdateAttendanceStatus,
      config?: AxiosRequestConfig,
    ) => {
      return request.post<Http.Response.TPostAttendanceUpdateStatus>(
        '/attendance/update-status',
        data && genFormData(data),
        config,
      );
    },
    getCampusAttendanceUserInfo: (_: void, config?: AxiosRequestConfig) => {
      return request.get<Http.Response.TGetCampusAttendanceUserInfo>(
        '/attendance/user-info',
        config,
      );
    },

    getCampusAttendanceStatus: (_: void, config?: AxiosRequestConfig) => {
      return request.get<Http.Response.TGetCampusAttendanceStatus>(
        '/attendance/status',
        config,
      );
    },
    postChangePassword: (
      data: Http.Request.TChangePassword,
      config?: AxiosRequestConfig,
    ) => {
      return request.post<
        Http.Response.TChangePassword,
        Http.Request.TChangePassword
      >('/rest-auth/password/change/', genFormData(data), config);
    },
    postRegisterUser: (data: Http.Request.TRegisterUser) => {
      return request.post<
        Http.Response.TRegisterUser,
        Http.Request.TRegisterUser
      >('/api-users/privacy/create/', genFormData(data));
    },

    getUserNodeProgress: (userId: number) => {
      return request.get<{ data: Http.Response.TUserNodeProgressStatus[] }>(
        `/api-dashboard/nodeprogrs/?group_by[status]&aggregate[Count]=id&search_fields=user_enrolments__user__id&search=${userId}`,
      );
    },

    getUserSubmitList: (params: Http.Request.TUserSubmitParams) => {
      return request.get<Http.IPagination<Http.Response.TUserSubmission>>(
        `/api-dashboard/usersubmission/`,
        {
          params,
        },
      );
    },
  },
  /**************************************************************
   *! NOTICE 공지사항
   *************************************************************/
  notice: {
    getNoticePost: (postId: string, config?: AxiosRequestConfig) => {
      return request.get<Http.Response.TNoticePost>(
        `/api-forum/notice/${postId}`,
        config,
      );
    },
    getNoticePosts: (pageNum?: number, config?: AxiosRequestConfig) => {
      return request.get<Http.Response.TGetNoticeList>(`/api-forum/notice/`, {
        ...(pageNum && { params: { page: pageNum } }),
        ...config,
      });
    },
    putNoticePost: (
      { postId, data }: Http.Request.TPutNoticePost,
      config?: AxiosRequestConfig,
    ) => {
      return request.put<Http.Response.TNoticePost, Http.Request.TNoticePost>(
        `/api-forum/notice/${postId}`,
        genFormData(data),
        config,
      );
    },
    uploadImageOnEditor: (
      data: Http.Request.TPostUploadImage,
      config?: AxiosRequestConfig,
    ) => {
      return request.post('/api-forum/upload/', genFormData(data), config);
    },
    deletePost: (postId: string, config?: AxiosRequestConfig) => {
      return request.delete(`/api-forum/notice/${postId}`, config);
    },
  },

  forum: {
    fetchTopicStageList: () => {
      return request.get<Http.Response.TForumStageList>(
        '/api-forum/topic-stages/',
      );
    },
    fetchTopicNodeList: (stageId: number) => {
      return request.get<Http.Response.TForumNodes>(
        `/api-forum/topic-nodes/?stage_id=${stageId}`,
      );
    },
    fetchTopicList: (params: Http.Request.TForumFilter) => {
      console.log('0627 params', params);
      return request.get<Http.IPagination<Http.Response.TForumTopic>>(
        `api-forum/topic/`,
        {
          params: params,
        },
      );
    },
    fetchTopicDetail: (topicId: string, config?: AxiosRequestConfig) => {
      return request.get<Http.Response.TForumTopic>(
        `/api-forum/topic/${topicId}/`,
        config,
      );
    },

    updateTopic: (
      topicId: number,
      data: Http.Request.TTopicUpdate,
      config?: AxiosRequestConfig,
    ) => {
      return request.patch(
        `/api-forum/topic/${topicId}/`,
        genFormData(data),
        config,
      );
    },
    deleteTopic: (topicId: number, config?: AxiosRequestConfig) => {
      return request.delete(`/api-forum/topic/${topicId}/`, config);
    },
    fetchPosts: (topicId: number, config?: AxiosRequestConfig) => {
      return request.get<Http.Response.TForumPost[]>(
        `/api-forum/topic/${topicId}/posts/`,
        config,
      );
    },

    postImageUpload: (formData: FormData) => {
      return request.post<Http.Response.TForumImageUpload>(
        '/api-forum/upload/',
        formData,
      );
    },

    postTopicLike: (topicId: number, config?: AxiosRequestConfig) => {
      return request.post(`/api-forum/topic/${topicId}/like/`, null, config);
    },
    deleteTopicLike: (topicId: number, config?: AxiosRequestConfig) => {
      return request.delete(`/api-forum/topic/${topicId}/like/`, config);
    },

    deletePost: (
      topicId: number,
      postId: number,
      config?: AxiosRequestConfig,
    ) => {
      return request.delete(`/api-forum/topic/${topicId}/posts/${postId}/`);
    },
    postTopicPost: (
      topicId: number,
      data: Http.Request.TTopicPost,
      config?: AxiosRequestConfig,
    ) => {
      return request.post(`/api-forum/topic/${topicId}/posts/`, data, config);
    },

    updateTopicPost: (
      topicId: number,
      postId: number,
      data: Http.Request.TTopicPost,
    ) => {
      return request.patch(
        `/api-forum/topic/${topicId}/posts/${postId}/`,
        data,
      );
    },

    postReplyLike: (
      topicId: number,
      postId: number,
      config?: AxiosRequestConfig,
    ) => {
      return request.post(
        `/api-forum/topic/${topicId}/posts/${postId}/like/`,
        null,
        config,
      );
    },

    deleteReplyLike: (
      topicId: number,
      postId: number,
      config?: AxiosRequestConfig,
    ) => {
      return request.delete(
        `/api-forum/topic/${topicId}/posts/${postId}/like/`,
      );
    },

    postCommentWithReply: (
      topicId: number,
      clickedReplyId: number,
      data: Http.Request.TReplyWithPost,
      config?: AxiosRequestConfig,
    ) => {
      // let formData = new FormData();
      // formData.append('content', data.content);

      return request.post(
        `/api-forum/topic/${topicId}/posts/${clickedReplyId}/reply/`,
        genFormData(data),
        config,
      );
    },

    fetchReplies: (
      topicId: number,
      postId: number,
      config?: AxiosRequestConfig,
    ) => {
      return request.get<Http.Response.TForumPost[]>(
        `/api-forum/topic/${topicId}/posts/${postId}/replies/`,
        config,
      );
    },
  },
};
