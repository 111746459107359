import { Navigate, useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '@store/hooks';
import {
  logout,
  selectDecodedToken,
  selectRole,
  selectUser,
} from '@store/slices';
import { useHasRole } from './helper';
import { User } from '@types';
import { HotToast, Toast } from '@utils';
import routes from '../../routes';
import { useEffect } from 'react';
import { setUserInfo } from '@utils/monitoring/datadog';
import {
  CookieOptions,
  ORM_MEMBER_URL,
  ORM_PORTAL_URL,
  sessionCookieName,
  redirectUrl,
} from '@constants';
import useCookieManager from '@hooks/useCookieManager';
import { Cookies } from 'react-cookie';

interface RequireAuthProps {
  redirectPath?: string;
  allowedRoles?: User.TRoles[];
  children: JSX.Element;
}

/**
 *
 * @description 최초로 들어오는 경우에는 useMountUser에서 한 번 걸리내지만
 */
const RequireAuth: React.FC<RequireAuthProps> = ({
  allowedRoles,
  children,
  redirectPath = '/',
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useAppSelector(selectUser);
  const roles = useAppSelector(selectRole);
  const decodedToken = useAppSelector(selectDecodedToken);
  const allowedAccess = useHasRole(allowedRoles, roles);
  const cookies = new Cookies();

  useEffect(() => {
    if (!decodedToken) {
      HotToast.error('로그인이 필요합니다!');

      dispatch(logout());

      cookies.remove(sessionCookieName, CookieOptions);
      cookies.remove(redirectUrl, CookieOptions);
      window.location.href = `${ORM_MEMBER_URL}`;
    } else {
      if (!user) {
        // #B-1-1. 초대받은 유저가 정보 등록을 하지 않은 경우
        if (decodedToken.term_agreement_yn === false) {
          HotToast.error('인증 및 약관동의가 필요한 페이지 입니다.');
          navigate(routes.common.RegisterInvitedUserPageRoute.path, {
            replace: true,
          });
          return;
        }
        // #B-1-2. 캠퍼스 아이디가 없거나 null - 정보오류 로그아웃
        if (!decodedToken.local_school_id) {
          HotToast.error('캠퍼스 정보가 없습니다. 관리자에게 문의해주세요.');
          dispatch(logout());
          navigate(routes.common.IntegratedSignInPageRoute.path);
          return;
        }
        // #B-1-3. 복수의 캠퍼스에 속한 유저인 경우 - 캠퍼스 선택 페이지
        if (decodedToken.local_school_id === -1) {
          HotToast.error(
            '해당 서비스는 캠퍼스를 선택하신 후에 이용 가능합니다.',
          );
          navigate(routes.common.SelectCampusPageRoute.path, { replace: true });
          return;
        }
      } else {
        setUserInfo({
          userId: user.userId.toString(),
          userName: user.name,
          localSchool: user.localSchool,
        });

        if (!allowedAccess) {
          HotToast.error('접근 권한이 없습니다.');
          navigate(-1);
          return;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [decodedToken]);

  return children;
};

export default RequireAuth;
