import classNames from 'classnames';
import useOrmBizUrls from '@hooks/useOrmBizUrls';

export const OrmFooter = () => {
  const currentYear = new Date().getFullYear();
  const ormbizUrls = useOrmBizUrls();

  return (
    <div
      className={classNames(
        'bg-black text-[12px] text-gray-300',
        'leading-[1.6]',
        'grid',
        'grid-cols-[24px_1fr_24px]',
        'md:grid-cols-[36px_1fr_36px]',
        'lg:grid-cols-[1fr_minmax(0,1008px)_1fr]',
        'py-[40px]',
        'md:py-[56px]',
        'lg:py-[64px]',
        'gap-y-[36px]',
      )}
    >
      <div
        className={`
        col-span-1 col-start-2 
        flex
        flex-col justify-between
        gap-[36px] 
        md:flex-row 
        md:items-end
        `}
      >
        <a href={`${ormbizUrls.ORMBIZ_PORTAL_DASHBOARD_URL}`}>
          <img
            src="/images/orm-logo-green-v1.png"
            alt="aiffel_logo"
            width={70}
            height={40}
          />
        </a>
        <div className="flex w-[140px] flex-col gap-[8px]">
          <div className="flex justify-between">
            <a
              href="https://www.youtube.com/@modulabs_"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/images/footer/orm/youtube_logo.svg"
                alt="youtube_logo"
              />
            </a>
            <a
              href="https://www.facebook.com/lab4all/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/images/footer/orm/facebook_logo.svg"
                alt="facebook_logo"
              />
            </a>
            <a
              href="https://www.instagram.com/modulabs_/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/images/footer/orm/instagram_logo.svg"
                alt="instagram_logo"
              />
            </a>
            <a
              href="https://join.slack.com/t/modulabs/shared_invite/zt-1o5pr4s39-sDlSNhnhaMGeqCBaNUvVsA"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/images/footer/orm/slack_logo.svg"
                alt="instagram_logo"
              />
            </a>
          </div>
          <nav
            className={`
              flex justify-between
              text-[12px] font-bold leading-[1.5]
          `}
          >
            <a
              className="text-white hover:text-[#00B277]"
              href={`${ormbizUrls.ORMBIZ_PORTAL_URL}/privacy-policy`}
              target="_blank"
              rel="noreferrer"
            >
              개인정보 보호
            </a>
            <a
              className="text-white hover:text-[#00B277]"
              href={`${ormbizUrls.ORMBIZ_PORTAL_URL}/terms-of-use`}
              target="_blank"
              rel="noreferrer"
            >
              이용 약관
            </a>
          </nav>
        </div>
      </div>
      <div
        className={`
        col-span-1 col-start-2 
        flex flex-col
        gap-y-[16px]
      `}
      >
        <div className="text-[16px] font-bold leading-[1.4] text-white">
          <a
            className="text-white hover:text-[#00B277]"
            href="https://modulabs.co.kr"
            target="_blank"
            rel="noreferrer"
          >
            (주)모두의연구소
          </a>
        </div>
        <div
          className={`
          flex 
          flex-col justify-between
          gap-y-[16px]
          lg:flex-row
          lg:items-end
        `}
        >
          <div>
            대표 김승일 | 개인정보보호책임자 안우진 | 서울특별시 강남구 강남대로
            324 역삼디오슈페리움 2층 | cs@ormbiz.co.kr | <br />
            사업자등록번호 : 517-88-00184 | 통신판매업신고 : 2017-서울강남-04920
            | 고객센터 : 010-7743-5349 | <br />
            주식회사 모두의연구소는 전자상거래 등에서의 소비자보호에 관한 법률에
            따른 통신판매업을 영위하고 있습니다.
          </div>
          <div>Copyright &copy;{currentYear} ORM. All Rights Reserved.</div>
        </div>
      </div>
    </div>
  );
};
