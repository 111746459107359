import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import LoadingPage from '@pages/Loading';
import {
  useInitiateTimer,
  useMountUser,
  useManualStorageHandler,
} from '@utils';
import { DebugWindow } from 'aiffel-jupyter-kernel';
import { useEffect } from 'react';
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { CookiesProvider, useCookies } from 'react-cookie';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { ThemeProvider } from 'styled-components';
import AppLayout from './components/AppLayout';
import AppRouter from './components/AppRouter';
import ErrorBoundary from './legacy/pages/PageExpired';
import { aiffelTheme } from './legacy/themes/aiffelTheme';
import { GlobalStyle } from './legacy/themes/global';
import { MobileDetector } from './mobile-detector';
import { ModalContainer } from './modal-manager';
import sassVariables from '@aui/variable.scss';
import { kernelConnector } from './hooks/useKernelConnector';
import { useAppSelector } from './store/hooks';

import 'semantic-ui-css/semantic.min.css';
import './App.scss';

import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/core/lib/css/blueprint.css';

import {
  ACCESS_TOKEN,
  BUILD_USAGE,
  HRD_FDS_DEBUG_MODE,
  sessionCookieName,
} from '@constants';
import { setItem } from '@utils/Storage';

const queryClient = new QueryClient();
export default function App() {
  const loading = useAppSelector((state) => state.auth.loading);
  const initialLoading = useMountUser();
  useInitiateTimer();
  useManualStorageHandler();

  const [cookies] = useCookies([sessionCookieName]);

  if (BUILD_USAGE === 'kdc') {
    const hasSessionCookie =
      cookies._ormSessionUser !== '' ||
      cookies._ormSessionUser !== undefined ||
      cookies._ormSessionUser !== null
        ? true
        : false;
    if (hasSessionCookie) {
      setItem(ACCESS_TOKEN, cookies._ormSessionUser);
    }
  }

  if (initialLoading) {
    return <LoadingPage message="사용자 정보를 가져오는 중입니다... 🚀" />;
  }

  if (loading) {
    return <LoadingPage message="정보를 가져오는 중입니다... 🚀" />;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <CookiesProvider>
        <ThemeProvider theme={aiffelTheme}>
          <RecoilRoot>
            <GlobalStyle />
            <ModalContainer />
            <ChangeFavicon />
            <MobileDetector mobileTriggerWidth={sassVariables.sizeMobile} />
            <Router>
              <OnPathChange />
              <ErrorBoundary>
                <ReactNotifications />
                <AppLayout>
                  <AppRouter />
                </AppLayout>
              </ErrorBoundary>
            </Router>
          </RecoilRoot>
        </ThemeProvider>
      </CookiesProvider>
      <ReactQueryDevtools initialIsOpen={false} position={'bottom-right'} />
      <DebugWindow kernelConnector={kernelConnector} />
    </QueryClientProvider>
  );
}

function ChangeFavicon() {
  useEffect(() => {
    if (BUILD_USAGE === 'kdc') {
      const link: HTMLAnchorElement | null =
        document.querySelector("link[rel*='icon']");

      if (link) {
        link.href = '/favicon_orm.ico';
      }
    }
  }, []);

  return null;
}

function OnPathChange() {
  const location = useLocation();
  useEffect(() => {
    // window.scrollY = 0;
    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
  }, [location.pathname]);
  return null;
}
